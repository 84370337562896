import "./Projects.css";
import PlanterestSmall from "../../assets/PlantrestSmall.jpg";
import DogSpotSmall from "../../assets/DogSpotSmall.jpg";
import CatalystSmall from "../../assets/CatalystSmall.jpg";

const Projects = (props) => {
  return (
    <div className="projects-container">
      <div className="project-div">
        <img src={PlanterestSmall} alt="project" />
        <div className="project-text">
          <h3>Planterest</h3>
          <p className="project-blurb">
            A Pinterest-like image board site for plant lovers. This was my
            largest and most complex solo project built with Ruby on Rails
            backend and React frontend.
          </p>
          <div className="project-links">
            <a
              href="https://github.com/Hanna-Boorom/planterest"
              target="blank"
              rel="noreferrer"
            >
              View the Code
            </a>
            <a
              href="https://planterest.netlify.app/"
              target="blank"
              rel="noreferrer"
            >
              View the Deployed Site
            </a>
          </div>
        </div>
      </div>

      <div className="project-div">
        <img src={DogSpotSmall} alt="project" />
        <div className="project-text">
          <h3>The Dog Spot</h3>
          <p className="project-blurb">
            I worked with two other group members to create this site which is a
            fictional resource for shelters around the US to list adoptable dogs
            for national exposure. Built with React frontend and
            Express/MongoDB/Mongoose backend
          </p>
          <div className="project-links">
            <a
              href="https://github.com/amarp86/The-Dog-Spot"
              target="blank"
              rel="noreferrer"
            >
              View the Code
            </a>
            <a
              href="https://the-dog-spot.netlify.app/"
              target="blank"
              rel="noreferrer"
            >
              View the Deployed Site
            </a>
          </div>
        </div>
      </div>

      <div className="project-div">
        <img src={CatalystSmall} alt="project" />
        <div className="project-text">
          <h3>Catalyst Productivity</h3>
          <p className="project-blurb">
            A simple and beautiful to-do list modeled after Momentum, the
            popular Chrome extension. It features a React frontend with an
            Airtable API.
          </p>
          <div className="project-links">
            <a
              href="https://github.com/Hanna-Boorom/Catalyst-Productivity-App"
              target="blank"
              rel="noreferrer"
            >
              View the Code
            </a>
            <a
              href="https://catalyst-productivity.netlify.app/"
              target="blank"
              rel="noreferrer"
            >
              View the Deployed Site
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
