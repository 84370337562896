import { FaLinkedin, FaGithubSquare } from "react-icons/fa";

const Footer = (props) => {
  return (
    <footer>
      <p>
        Dev + Design by Hanna Boorom
        <a
          className="footer-icon"
          href="https://www.linkedin.com/in/hanna-boorom/"
          rel="noreferrer"
          target="blank"
        >
          <FaLinkedin />
        </a>
        <a
          className="footer-icon"
          href="https://github.com/Hanna-Boorom"
          rel="noreferrer"
          target="blank"
        >
          <FaGithubSquare />
        </a>
      </p>
    </footer>
  );
};

export default Footer;
