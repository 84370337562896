import { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { CgFileDocument } from "react-icons/cg";
import { SideNavData } from "./SideNavData";
import "./SideNav.css";

export default function SideNav() {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <div className={sidebar ? "sidenav-hidden" : "sidenav"}>
        <Link to="#" className="menu-bars">
          <FaBars onClick={showSidebar} />
        </Link>
      </div>
      <nav className={sidebar ? "nav-menu-active" : "nav-menu"}>
        <ul className="nav-menu-items" onClick={showSidebar}>
          <li className="navbar-toggle">
            <Link to="#" className="menu-bars">
              <AiOutlineClose />
            </Link>
          </li>
          {SideNavData.map((item, index) => {
            return (
              <li key={index} className={item.cName}>
                <Link to={item.path}>
                  {item.icon}
                  <span>{item.title}</span>
                </Link>
              </li>
            );
          })}
          <li className="nav-text">
            <CgFileDocument />

            <a
              id="resume-sidenav"
              href="https://docs.google.com/document/d/1wLsjHigErJvRh8Ga-6F0day0xUixR6UC3yML8LS4HBA/edit?usp=sharing"
              target="blank"
              rel="noreferrer"
            >
              <span className="resume-sidenav">Resume</span>
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
}
