import React from "react";
import "./Layout.css";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import pinkBackground from "../../../assets/pinkGeoBackground.png";
import purpleBackground from "../../../assets/mb-fakurian-unsplash.jpg";

const Layout = (props) => (
  <div className="layout">
    <Nav user={props.user} />
    <img id="background" src={pinkBackground} alt="pink geo" />
    {/* <img id="background" src={purpleBackground} alt="purple background" /> */}

    <div className="layout-children">{props.children}</div>
    <Footer />
  </div>
);

export default Layout;
