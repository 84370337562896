import * as FaIcons from "react-icons/fa";
import * as HiIcons from "react-icons/hi";

export const SideNavData = [
  {
    title: "Projects",
    path: "/projects",
    icon: <FaIcons.FaCode />,
    cName: "nav-text",
  },
  {
    title: "Contact",
    path: "/contact",
    icon: <HiIcons.HiOutlineMail />,
    cName: "nav-text",
  },
];
