import "./About.css";
import cssLogo from "../../assets/cssLogo.png";
import gitLogo from "../../assets/gitLogo.png";
import HTML5Logo from "../../assets/HTML5Logo.png";
import JavascriptLogo from "../../assets/js-logo.png";
import mongoDBLogo from "../../assets/mongodb-logo.png";
import nodeJsLogo from "../../assets/node_js_logo.png";
import postreSQLLogo from "../../assets/postgreSqlLogo.png";
import reactLogo from "../../assets/react_js_logo.png";
import rubyOnRailsLogo from "../../assets/rubyOnRailsLogo.png";

const About = (props) => {
  return (
    <div className="about-main-container">
      <div className="about-me">
        <h2>About Me</h2>
        <p>
          I'm a full-stack developer specializing in MERN stack, with a hunger
          to learn all I can. I have an extensive background in customer
          experience and a Bachelor's degree in Hospitality Management from the
          University of Illinois at Urbana-Champaign (I-L-L!)
        </p>
        <p>
          {" "}
          My hunger for knowledge led me to the world of web development where I
          began self-guided learning. In January of this year, I finally took
          the plunge and began General Assembly’s Software Engineering Immersive
          program. After 12 rigorous weeks, 400+ hours of instruction, and work,
          I completed 5 major projects and graduated with flying colors. I’m
          excited to learn as much as I can and am looking for employment with a
          forward-thinking company with a purpose that will allow me to hone my
          skills and grow as a developer.
        </p>
        <span className="home-link-about">
          <a
            href="https://drive.google.com/file/d/1g038zzlsq6frR5Rb1-nZak3OpjjsMplU/view?usp=sharing"
            target="blank"
            rel="noreferrer"
          >
            Check out my resume HERE
          </a>
        </span>
      </div>
      <div className="skills-section">
        <h2>Skills</h2>
        <p className="skills-intro">
          I currently work with the following languages/frameworks, but am
          always eager to attain new skills:
        </p>
        <div className="skills-list-container">
          <ul className="skills-names-list">
            <li>HTML</li>
            <li>CSS</li>
            <li>JavaScript</li>
            <li>React</li>
            <li>Express</li>
            <li>Node.js</li>
            <li>MongoDB</li>
            <li>SQL</li>
            <li>PostgreSQL</li>
            <li>Ruby/Ruby on Rails</li>
            <li>Heroku</li>
            <li>Git/Github</li>
            <li>Adobe Illustrator</li>
            <li>Adobe XD</li>
          </ul>
        </div>

        <div className="logos">
          <img className="indiv-logo" src={HTML5Logo} alt="HTML" />
          <img className="indiv-logo" src={cssLogo} alt="CSS" />
          <img className="indiv-logo" src={JavascriptLogo} alt="Javascript" />
          <img className="indiv-logo" src={reactLogo} alt="React" />
          <img className="indiv-logo" src={nodeJsLogo} alt="Node" />
          <img className="indiv-logo" src={mongoDBLogo} alt="MongoDB" />
          <img className="indiv-logo" src={postreSQLLogo} alt="PostGresQL" />
          <img
            className="indiv-logo"
            src={rubyOnRailsLogo}
            alt="Ruby on Rails"
          />
          <img className="indiv-logo" src={gitLogo} alt="Git" />
        </div>
      </div>
    </div>
  );
};

export default About;
