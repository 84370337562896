import TextLoop from "react-text-loop";
import headshot from "../../assets/HB-headshot-tight-zoom.jpg";
import "./Home.css";

const Home = (props) => {
  return (
    <div className="home-container">
      <img className="home-headshot" src={headshot} alt="headshot" />
      <h1 className="home-h1">Hi, I'm Hanna: </h1>

      <div className="text-loop">
        <TextLoop mask={true}>
          <span className="text-loop">Full-Stack Software Developer</span>
          <span className="text-loop">Outdoor Enthusiast</span>
          <span className="text-loop">Foodie</span>
          <span className="text-loop">Illini</span>
          <span className="text-loop">All Around Awesome Girl!</span>
        </TextLoop>
      </div>
    </div>
  );
};

export default Home;
