import { NavLink } from "react-router-dom";
import logo from "../../../assets/HBLogo.png";
import SideNav from "../SideNav/SideNav";
import * as FaIcons from "react-icons/fa";
import { CgFileDocument } from "react-icons/cg";
import * as HiIcons from "react-icons/hi";

const Nav = () => {
  return (
    <div>
      <nav>
        <div className="nav-logo">
          <NavLink to="/">
            <img src={logo} alt="logo" />
          </NavLink>
        </div>
        <div className="nav-links-container">
          <NavLink to="/projects">
            {" "}
            <span className="nav-link contact-proj">
              <FaIcons.FaCode /> Projects
            </span>
          </NavLink>
          <NavLink to="/contact">
            {" "}
            <span className="nav-link contact-proj">
              <HiIcons.HiOutlineMail /> Contact Me
            </span>
          </NavLink>
          <span className="nav-link">
            <a
              href="https://docs.google.com/document/d/1wLsjHigErJvRh8Ga-6F0day0xUixR6UC3yML8LS4HBA/edit?usp=sharing"
              target="blank"
              rel="noreferrer"
            >
              <CgFileDocument /> Resume
            </a>
          </span>
        </div>
        <SideNav />
      </nav>
    </div>
  );
};

export default Nav;
